<template>
  <div>
    <title>SIM INVENTORY ~ PURCHASE REQUEST DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Add Purchase Request Data
        <br />
        <h4>The following is a list of purchase request data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Request Item Data</h3>
                <br /><br />
                <div class="row">
                  <div class="col-md-6">
                    <b style="font-size: 15px">Type</b>
                    <p></p>
                    <select v-model="isidata.tipepilih" @change="pilihtipe($event)" class="form-control">
                      <option value="-" disabled>--please select--</option>
                      <option value="bom">B.O.M</option>
                      <option value="material">Material</option>
                      <option value="aksesoris">Accessories</option>
                      <option value="general">General</option>
                    </select>
                  </div>
                </div>
              </div>
              <p></p>
              <div v-if="isShowAwal">
                <div class="box-body">
                  <div class="row">
                    <div class="col-md-6">
                      Show &nbsp; :
                      <select
                        v-model="pageSize"
                        @change="handlePageSizeChange($event)"
                      >
                        <option
                          v-for="size in pageSizes"
                          :key="size"
                          :value="size"
                        >
                          {{ size }}
                        </option>
                      </select>
                      results
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-5">
                      <input
                        type="text"
                        autocomplete="off"
                        placeholder="Search by Item Name"
                        class="form-control"
                        v-model="searchreq"
                      />
                    </div>
                    <div class="col-md-1">
                      <button
                        @click="fetchData()"
                        type="button"
                        class="btn btn-info"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                      <select
                        class="form-control select2"
                        name="order"
                        id="order"
                        v-model="order"
                        @change="fetchData()"
                      >
                        <option value="ALL">--All--</option>
                        <option
                          v-for="listorder in orders"
                          :key="listorder.id"
                          :value="listorder.order_number"
                        >
                          {{ listorder.order_number }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isShowAwal">
              <div v-if="isExist" class="box-body">
                <div class="table-responsive">
                  <table id="mydata" class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Request</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(classdata, index) in list_paged" :key="index">
                        <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                        <td>{{ classdata.kode_barang }}</td>
                        <td>{{ classdata.nama_barang }}</td>
                        <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                        <td>
                          <button
                            title="Request"
                            class="btn btn-info"
                            @click="addPO(classdata)"
                          >
                            <i class="fa fa-save"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div>
                    <v-pagination
                      v-model="page"
                      :pages="count"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="handlePageChange"
                    />
                  </div>
                </div>
                <div class="box-footer">
                  <button class="btn btn-info" @click="addPO(this.list_paged)">
                    Request
                  </button>
                </div>
              </div>
              <div v-else class="box-body">
                <p class="text-center text-red">--Data not found--</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Item Request</h3>
              </div>
              <!-- /.box-header -->

              <div class="box-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="box-header with-border">
                      <i class="fa fa-save"></i>
                      <h3 class="box-title">Save Data</h3>
                    </div>
                    <!-- /.box-header -->
                    <!-- form start -->
                    <form role="form">
                      <div class="box-body">
                        <div class="form-group">
                          <label for="exampleInputEmail1"
                            >Request PO Number</label
                          >
                          <input
                            type="text"
                            v-model="isidata.reqponumber"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Request PO Number"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <!-- general form elements -->
                    <div class="box-header with-border">
                      <i class="fa fa-save"></i>
                      <h3 class="box-title">Save Data</h3>
                    </div>
                    <!-- /.box-header -->
                    <!-- form start -->
                    <form role="form">
                      <div class="box-body">
                        <div class="form-group">
                          <label for="exampleInputEmail1"
                            >Request PO Date</label
                          >
                          <input
                            type="date"
                            readonly
                            v-model="isidata.reqpodate"
                            autocomplete="off"
                            class="form-control"
                            placeholder="PO Date"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <!-- <div class="box-body">
                <div class="row">
                  <div class="col-md-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-md-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-md-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div> -->
              <div class="table-responsive">
                <table id="mydatapo" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <!-- <th>No.</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <!-- <th>Extra</th>
                      <th>Qty PO</th>
                      <th>Notes</th>
                      <th>Price</th>
                      <th>Unit Price</th>
                      <th>Convertion</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="classdata in list_po" :key="classdata.id">
                      <!-- <td>{{ index + 1 + (page - 1) * pageSize }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <!-- <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>
                        <input
                          :id="'inpn-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.notespod"
                          :name="'nmn-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpp-' + classdata.id"
                          type="number"
                          placeholder="ex : 3500"
                          required=""
                          class="form-control"
                          v-model="classdata.pricepod"
                          :name="'nmp-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpu-' + classdata.id"
                          type="text"
                          class="form-control"
                          placeholder="ex : Rp"
                          required=""
                          v-model="classdata.unitpricepod"
                          :name="'nmu-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpc-' + classdata.id"
                          type="number"
                          class="form-control"
                          placeholder="ex : 1 / 2 / 3"
                          required=""
                          v-model="classdata.konversi"
                          :name="'nmc-' + classdata.id"
                        />
                      </td> -->
                    </tr>
                  </tbody>
                </table>

                <!-- <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div> -->
              </div>
            </div>
          </div>
          <button @click="saveData()" type="button" class="btn btn-primary">
            <i class="fa fa-save"></i>
            Save
          </button>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      isExist: true,
      isAdded: false,
      isShowAwal: false,
      namesearch: "",
      searchreq: "",
      list_paged: [],
      list_po: [],
      order: "ALL",
      orders: [],
      suplist: [],
      categorylist: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      valuesup: "",
      pageSizes: [10, 50, 100, 500],
      isidata: {
        reqponumber: "",
        reqpodate: "",
        tipepilih:"-"
      },
      classdata: {
        notespod: "",
        pricepod: "",
        unitpricepod: "",
        konversi: "",
      },
    };
  },
  created() {
    this.fetchData();
    this.getOrder();
    this.getnow();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.reqpodate = tahun + "-" + month + "-" + tgl;
    },
    pilihtipe(event){
      if(event.target.value == 'bom') {
        this.isShowAwal = true
      } else {
        this.isShowAwal = false
      }
    },
    saveData() {
      const numbporeq = this.isidata.reqponumber;
      const podatereq = this.isidata.reqpodate;
      const kodeuser = sessionStorage.getItem("kodeuser");
      const tokenlog = sessionStorage.getItem("token");
      const depart = localStorage.getItem("deptkar");
      const datagetgridpacklist = JSON.parse(
        localStorage.getItem("dtpoureq") || "[]"
      );

      /* const paramdata = {
        nomor_req_po: numbporeq,
        dept: depart,
        tanggal_req: podatereq,
        kode_user: kodeuser,
      }; */

      let paramdata = new FormData();

      paramdata.append("nomor_req_po", numbporeq);
      paramdata.append("dept", depart);
      paramdata.append("tanggal_req", podatereq);
      paramdata.append("kode_user", kodeuser);

      const urlAPIsavedata =
        this.$apiurl + "purchase_request_h/savepurchase_request_h";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlog,
      };
      axios
        .post(urlAPIsavedata, paramdata, { headers: headers })
        .then(() => {
          for (let i in datagetgridpacklist) {
            const paramdatadetail = {
              nomor_req_po: numbporeq,
              order_number: this.order,
              kode_barang: datagetgridpacklist[i].kode_barang,
              quantity: datagetgridpacklist[i].qty,
            };
            console.log(paramdatadetail);
            const tokenlogin = sessionStorage.getItem("token");
            const urlAPIsavedatadet =
              this.$apiurl + "purchase_request_d/savepurchase_request_d";
            const headers = {
              Accept: "application/json",
              Authorization: tokenlogin,
            };

            if (
              Number(paramdatadetail.quantity) > 0 &&
              paramdatadetail.kode_barang != "" &&
              paramdatadetail.nomor_req_po != "" &&
              paramdatadetail.order_number != ""
            ) {
              axios
                .post(urlAPIsavedatadet, paramdatadetail, { headers })
                .then((resp) => {
                  console.log(resp);
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save data detail successfully",
                    showConfirmButton: false,
                  });
                  this.loading = false;
                })
                .catch((err) => {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: err.response.data,
                    showConfirmButton: false,
                  });
                  console.log(err);
                  this.loading = false;
                });
            }
          }
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err,
            showConfirmButton: false,
          });
          this.loading = false;
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/po_header/getalldatapo_header?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "packing_list/getallv_temp_request?length=" +
        this.pageSize +
        "&cari=" +
        this.searchreq +
        "&order_number=" +
        this.order +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    getOrder() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "buyer_order/getdaftar_buyer_order_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isAdded = false;
          } else {
            this.isAdded = true;
            this.orders = resp.data.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/po_header/getalldatapo_header?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "packing_list/getallv_temp_request?cari=" +
        this.namesearch +
        ";" +
        this.cari +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/po_header/hapuspo_header/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "packing_list/hapuspacking_list/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    addPO(data) {
      // console.log(Number(data.length));
      if (this.list_po.includes(data)) {
        console.log("data sudah ada");
      } else {
        if (data.length > 1) {
          this.list_po = this.list_paged;
          localStorage.setItem("dtpoureq", JSON.stringify(this.list_po));
        } else {
          this.list_po = this.list_po.concat(data);
          localStorage.setItem("dtpoureq", JSON.stringify(this.list_po));
        }
      }
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
